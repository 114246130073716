.menu-container {
  position: relative;
  
}

.menu {
  /* background: #ffffff; */
  /* border-radius: 8px; */
  background: #116466;
  position: absolute;
  top: 60px;
  right: 0;
  width: 300px;
  /* box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3); */
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  z-index: 1;
}

.menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu li {
  border-bottom: 1px solid #ffffff;
}

.menu li a {
  text-decoration: none;
  color: white;
  padding: 15px 20px;
  display: block;
}

.menu-trigger {
  /* background: #ffffff; */
  filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(299deg) brightness(107%) contrast(101%);
  background: none;
  /* border-radius: 90px; */
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 6px;
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3); */
  border: none;
  vertical-align: middle;
  transition: box-shadow 0.4s ease;
}

.menu-trigger:hover {
  /* box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3); */
}

.menu-trigger span {
  font-weight: 700;
  vertical-align: middle;
  font-size: 14px;
  margin: 0 10px;
}

/* // FIXME: MAYBE remove later */
.menu-trigger img {
  border-radius: 90px;
}


